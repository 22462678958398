/* Global Styles */
html, body {
    height: 100%;
    margin: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
  
body {
    box-sizing: border-box;
    overflow-y: auto;
}
  
*, *::before, *::after {
    box-sizing: inherit;
}
  
/* NavbarHome */
.navbar-home {
    width: 100%;
    background-color: #F3F3F3;
    padding: 15px 15px 15px 15px;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    box-sizing: border-box;
    z-index: 10;
    position: relative;
}
  
.navbar-home-container {
    width: 100%;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 7px 20px;
    border-radius: 5px;
    max-width: 1500px;
    position: relative;
}
  
.navbar-home-logo-container {
    display: flex;
    align-items: center;
}
  
.navbar-home-logo-link {
    display: inline-block;
}
  
.navbar-home-logo-link:hover .navbar-home-logo-image {
    filter: brightness(0) saturate(100%) invert(79%) sepia(10%) saturate(1503%) hue-rotate(145deg) brightness(91%) contrast(88%);
    transition: filter 0.5s ease;
}
  
.navbar-home-logo-image {
    height: 40px;
    margin-right: 10px;
    transition: filter 0.5s ease;
    margin-top: 8px;
}

.nav-links-container {
    position: relative;
}
  
.navbar-home-nav-links {
    list-style: none;
    display: flex;
    gap: 2.5rem;
    font-family: 'Inter';
    padding-left: 0px;
}

@media(max-width:500px) {
    .navbar-home-nav-links a {
        font-size: 20px!important;
    }
}
  
.navbar-home-nav-links li {
    position: relative;
    align-self:center!important;
}
  
.navbar-home-nav-links a {
    font-family: 'Inter';
    text-decoration: none;
    color: #000;
    font-size: 22px;
    transition: color 0.5s ease;
}
  
.navbar-home-nav-links a:hover {
    color: #9cdcf8;
}
  
.navbar-home-active-link {
    color: #9cdcf8 !important;
}
  
@media (min-width: 1230px) {
    .navbar-home-nav-links {
        display: flex;
    }
  
    .navbar-home-dropdown-container {
        display: none;
    }
  
    .navbar-home-dropdown-menu {
        display: none !important;
    }

    .navbar-home-extra-link {
        display: block;
    }
}

@media (max-width: 1230px) {
    .navbar-home-nav-links {
        display: flex;
        gap: 1.5rem;
        margin-bottom: 15px;
    }
  
    .navbar-home-extra-link {
        display: none;
    }
  
    .navbar-home-dropdown-container {
        display: block;
        position: relative;
    }

    .navbar-home-dropdown-toggle {
        display: block;
        cursor: pointer;
        font-size: 18px;
        padding-left: 1rem;
        color: #000;
        transition: color 0.3s ease;
    }

    .navbar-home-dropdown-toggle:hover {
        color: #9cdcf8;
    }
  
    .navbar-home-dropdown-menu {
        position: absolute;
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        top: 100%;
        right: 0;
        z-index: 1000;
        padding: 0.75rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0;
        opacity: 0;
        transform: translateY(-10px);
        transition: all 0.2s ease;
        visibility: hidden;
        font-family: 'Inter';
        font-size: 20px;
        text-align: left;
        list-style: none;
        min-width: 220px;
    }

    .navbar-home-dropdown-menu li {
        width: 100%;
    }

    .navbar-home-dropdown-menu a {
        display: block;
        width: 100%;
        padding: 0.5rem 0;
    }

    .navbar-home-dropdown-container:hover .navbar-home-dropdown-menu {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
    }
  
    .navbar-home-dropdown-menu li {
        margin: 0;
    }

    @media (max-width: 450px) {
        .navbar-home-dropdown-menu {
            padding: 0.75rem 1.5rem;
            margin: 0.5rem;
            font-size: 16px;
            right: -10px;
        }
    }
}
  
.navbar-home.sticky {
    position: fixed;
    top: 50px;
    width: 100%;
    background-color: white;
    padding: 0.5rem 0;
    transition: padding 0.3s ease, font-size 0.3s ease;
    z-index: 999;
}
  
.navbar-home.sticky .navbar-home-nav-links a {
    font-size: 24px;
}

@media (max-width:420px) {
    .navbar-home-logo-image { 
        height: 30px;
    }

    .navbar-home-nav-links a {
        font-size: 16px!important;
    }
}

/* Sell dropdown styles */
.sell-nav-item {
    display: flex;
    align-items: center;
    position: relative;
}

.sell-link-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sell-link-container a {
    pointer-events: none;
}

.sell-link-container:hover a,
.sell-link-container:hover .sell-dropdown-toggle {
    color: #9cdcf8;
}

.sell-dropdown-toggle {
    margin-left: 5px;
    font-size: 12px;
    color: #000;
    transition: color 0.5s ease;
}

.sell-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 0.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0s linear 0.2s;
    visibility: hidden;
    list-style: none;
    min-width: 200px;
}

.sell-dropdown-menu li {
    width: 100%;
}

.sell-dropdown-menu a {
    white-space: nowrap;
    font-size: 16px !important;
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    text-align:left!important;
}

.sell-nav-item:hover .sell-dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0s;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .sell-dropdown-menu {
      display: none;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  
    .sell-dropdown-menu.show {
      display: block;
      opacity: 1;
    }
  
    .sell-link-container.mobile {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  
    .rotate-180 {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  }
  
  /* Preserve desktop behavior */
  @media (min-width: 769px) {
    .sell-dropdown-menu {
      /* Keep your existing desktop dropdown styles */
      display: none;
    }
  
    .sell-nav-item:hover .sell-dropdown-menu {
      display: block;
    }
  }

  /* About dropdown styles */
.about-nav-item {
    display: flex;
    align-items: center;
    position: relative;
}

.about-link-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.about-link-container a {
    pointer-events: none;
}

.about-link-container:hover a,
.about-link-container:hover .about-dropdown-toggle {
    color: #9cdcf8;
}

.about-dropdown-toggle {
    margin-left: 5px;
    font-size: 12px;
    color: #000;
    transition: color 0.5s ease;
}

.about-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 0.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0s linear 0.2s;
    visibility: hidden;
    list-style: none;
    min-width: 200px;
}

.about-dropdown-menu li {
    width: 100%;
}

.about-dropdown-menu a {
    white-space: nowrap;
    font-size: 16px !important;
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    text-align: left !important;
}

.about-nav-item:hover .about-dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0s;
}

/* Mobile-specific styles for About dropdown */
@media (max-width: 768px) {
    .about-dropdown-menu {
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    
    .about-dropdown-menu.show {
        display: block;
        opacity: 1;
    }
    
    .about-link-container.mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    .about-nav-item {
        display: none;  /* Hide in mobile view as it moves to dropdown */
    }
}

/* Buy dropdown styles */
.buy-nav-item {
    display: flex;
    align-items: center;
    position: relative;
}

.buy-link-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.buy-link-container a {
    pointer-events: none;
}

.buy-link-container:hover a,
.buy-link-container:hover .buy-dropdown-toggle {
    color: #9cdcf8;
}

.buy-dropdown-toggle {
    margin-left: 5px;
    font-size: 12px;
    color: #000;
    transition: color 0.5s ease;
}

.buy-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 0.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0s linear 0.2s;
    visibility: hidden;
    list-style: none;
    min-width: 200px;
}

.buy-dropdown-menu li {
    width: 100%;
}

.buy-dropdown-menu a {
    white-space: nowrap;
    font-size: 16px !important;
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    text-align: left !important;
}

.buy-nav-item:hover .buy-dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0s;
}

/* Mobile-specific styles for Buy dropdown */
@media (max-width: 768px) {
    .buy-dropdown-menu {
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    
    .buy-dropdown-menu.show {
        display: block;
        opacity: 1;
    }
    
    .buy-link-container.mobile {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

/* Mobile dropdown styles - Add these to your existing CSS */
@media (max-width: 768px) {
    /* Common styles for all dropdown menus on mobile */
    .sell-dropdown-menu,
    .buy-dropdown-menu,
    .about-dropdown-menu,
    .navbar-home-dropdown-menu {
        position: absolute;
        
        right: 0;
        width: 100vw;
        min-width: 100vw;
        margin: 0;
        padding: 0;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        transform: translateX(0);
        background-color: white;
        margin:0px;
    }

    /* Adjust the position to account for navbar padding */
    .sell-dropdown-menu,
    .buy-dropdown-menu,
    .about-dropdown-menu {
        margin-left: -20px; /* Negative margin to counteract navbar padding */
    }

    /* Style the dropdown menu items */
    .sell-dropdown-menu li,
    .buy-dropdown-menu li,
    .about-dropdown-menu li,
    .navbar-home-dropdown-menu li {
        padding: 0;
        border-bottom: 1px solid #eaeaea;
    }

    .sell-dropdown-menu li:last-child,
    .buy-dropdown-menu li:last-child,
    .about-dropdown-menu li:last-child,
    .navbar-home-dropdown-menu li:last-child {
        border-bottom: none;
    }

    .sell-dropdown-menu a,
    .buy-dropdown-menu a,
    .about-dropdown-menu a,
    .navbar-home-dropdown-menu a {
        padding: 15px 20px;
        font-size: 18px !important;
    }

    /* Main navbar dropdown specific adjustments */
    .navbar-home-dropdown-menu {
        right: -20px; /* Adjust for navbar padding */
        width: 100vw;
    }
    
    /* Ensure dropdown containers are properly positioned */
    .sell-nav-item.mobile,
    .buy-nav-item.mobile {
        position: static;
    }

    /* Container positioning for proper dropdown alignment */
    .nav-links-container {
        position: static;
    }
}