/* Keep the existing styles up to the reviews container */
.sell-my-home-page.page-container {
  width: 100% !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
  position: relative !important;
  background: linear-gradient(to bottom, #ffffff, #f8f8f8) !important;
  padding: 60px 0px !important;
  overflow: hidden !important;
}

.sell-my-home-page.page-container .video-container {
  width: 40%;
  padding-bottom: 0px !important;
  min-height: 100px !important;
  height: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.steps-container h2 {
  font-size: 40px !important;
    color: #333 !important;
    margin-bottom: 40px !important;
    font-weight: bold !important;
    margin: auto;
    text-align: center;
}

@media (max-width: 768px) {
  .steps-container h2 {
    font-size: 28px !important;
    width: 100% !important;
    padding: 20px 20px !important;
    margin-bottom:0px!important;
  }
}

/* Background decorations */
.sell-my-home-page.page-container .background-decor {
  position: absolute !important;
  border-radius: 5px !important;
  filter: blur(60px) !important;
  opacity: 0.2 !important;
}

.sell-my-home-page.page-container .decor-top-left {
  top: 20px !important;
  left: 20px !important;
  width: 288px !important;
  height: 288px !important;
  background-color: #cce4ff !important;
}

.sell-my-home-page.page-container .decor-bottom-right {
  bottom: 20px !important;
  right: 20px !important;
  width: 288px !important;
  height: 288px !important;
  background-color: #e5d4ff !important;
}

/* Main content layout */
.sell-my-home-page.page-container .sell-home-landing-content {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  max-width: 1500px !important;
  margin: 0 auto !important;
  gap: 40px !important;
  padding-bottom: 60px;
}

/* Left column styles */
.sell-my-home-page.page-container .sell-home-landing-left {
  flex: 0 0 50% !important;
  text-align: left !important;
  padding: 0px !important;
}

.sell-my-home-page.page-container .sell-home-landing-right {
  position: relative !important;
  flex: 0 0 50% !important;
}

.sell-my-home-page.page-container .sell-home-landing-title {
  font-size: 42px !important;
  font-weight: bold !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  line-height: 1.2 !important;
  font-family: 'Inter';
}

.sell-my-home-page.page-container .sell-home-landing-title .brand-color {
  color: #9CDCF8 !important;
}

.sell-my-home-page.page-container .sell-home-landing-subtitle {
  font-size: 18px !important;
  color: black !important;
  margin-bottom: 30px !important;
  line-height: 1.6 !important;
  font-family: 'Inter' !important;
}

.sell-my-home-page.page-container .sell-home-landing-button {
  background-color: #9CDCF8 !important;
  color: #000 !important;
  padding: 14px 28px !important;
  border: none !important;
  font-family: 'Inter' !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  margin-right: 10px;
}

.sell-my-home-page.page-container .sell-home-landing-button-right {
  background-color: #000 !important;
  color: white !important;
  padding: 14px 28px !important;
  border: none !important;
  font-family: 'Inter' !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  margin-right: 10px;
}

.sell-my-home-page.page-container .sell-home-landing-button:hover {
  background-color: #7cb8d6 !important;
}

.sell-my-home-page.page-container .sell-home-landing-badge {
  display: inline-block !important;
  background-color: #9CDCF8 !important;
  color: black !important;
  padding: 8px 16px !important;
  font-family: 'Inter' !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}

/* Reviews Container */
.sell-my-home-page.page-container .reviews-container {
  position: relative !important;
  width: 100% !important;
  max-height: 500px !important;
  overflow: hidden !important;
  border-radius: 5px !important;
  
  
  padding: 0px !important;
  z-index: 2 !important;
}

.sell-my-home-page.page-container .read-more-reviews {
  text-align:left!important;
  font-size:15px;
  text-decoration:underline;
  color:black;

}

.sell-my-home-page.page-container .reviews-background {
  background-color:white;
}

.sell-my-home-page.page-container .review-card {
  position: relative !important;
  background-color: white !important;
  padding: 30px !important;
  border-radius: 5px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s ease, opacity 0.3s ease !important;
  font-family: 'Inter', sans-serif !important;
  z-index: 2 !important;
  max-height: 400px !important;
  overflow: hidden !important;
  margin: 20px !important;
  backdrop-filter: blur(10px) !important;
}

.sell-my-home-page.page-container .review-card:hover {
  transform: translateY(-5px) !important;
}

.sell-my-home-page.page-container .review-progress {
  display: flex !important;
  gap: 8px !important;
  margin-bottom: 20px !important;
  justify-content: start !important;
}

.sell-my-home-page.page-container .progress-dot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 5px !important;
  background-color: #e0e0e0 !important;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
  cursor: pointer !important;
}

.sell-my-home-page.page-container .progress-dot.active {
  background-color: #9CDCF8 !important;
  transform: scale(1.2) !important;
}

.sell-my-home-page.page-container .progress-dot:hover {
  background-color: #7cb8d6 !important;
}

.sell-my-home-page.page-container .review-header {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px !important;
  justify-content: space-between !important;
}

.sell-my-home-page.page-container .google-logo {
  width: 32px !important;
  height: auto !important;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)) !important;
}

.sell-my-home-page.page-container .review-rating {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}

.sell-my-home-page.page-container .stars {
  display: flex !important;
  gap: 2px !important;
}

.sell-my-home-page.page-container .star {
  color: #FFD700 !important;
  font-size: 18px !important;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)) !important;
}

.sell-my-home-page.page-container .rating-text {
  color:black;
  font-size: 15px !important;
  font-family:'Inter';
  font-weight: 500 !important;
  
}

.sell-my-home-page.page-container .review-content {
  margin-bottom: 20px !important;
}

.sell-my-home-page.page-container .review-author {
  font-weight: bold !important;
  color: #333 !important;
  font-size: 18px !important;
  margin-bottom: 4px !important;
  text-align:left;
}

.sell-my-home-page.page-container .review-date {
  color: #999 !important;
  font-size: 14px !important;
  margin-bottom: 12px !important;
  text-align:left;
}

.sell-my-home-page.page-container .review-text {
  color: #555 !important;
  line-height: 1.6 !important;
  font-size: 15px !important;
  max-height: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align:left;
}

.sell-my-home-page.page-container .review-navigation {
  display: flex !important;
  justify-content: start;
  margin-top: 20px !important;
}



.sell-my-home-page.page-container .nav-button {
  background: none !important;
  border: none !important;
  color: black !important;
  font-size: 25px !important;
  cursor: pointer !important;
  margin-right:10px;
  margin-bottom:10px;
  background-color:#9CDBF8!important;
  padding: 5px 15px  !important;
  transition: color 0.3s ease, transform 0.3s ease !important;
  border-radius: 5px !important;
}

.sell-my-home-page.page-container .nav-button:hover {
  color: #7cb8d6 !important;
  transform: scale(1.1) !important;
}

/* Animation classes */
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.sell-my-home-page.page-container .fade-out {
  animation: fadeOut 0.3s forwards !important;
}

.sell-my-home-page.page-container .fade-in {
  animation: fadeIn 0.3s forwards !important;
}

/* Gradient border effect */
.sell-my-home-page.page-container .review-card::before {
  content: '' !important;
  position: absolute !important;
  top: -2px !important;
  left: -2px !important;
  right: -2px !important;
  bottom: -2px !important;
  background: white !important;
  border-radius: 5px !important;
  z-index: -1 !important;
  opacity: 0.7 !important;
  transition: opacity 0.3s ease !important;
}

.sell-my-home-page.page-container .review-card:hover::before {
  opacity: 1 !important;
}

.sell-my-home-page.page-container .review-card.mobile-only {
  display: none !important;
}

/* Counter Section */
.sell-my-home-page.page-container .counter-section {
  max-width: 100%;
  padding-top: 60px;
  text-align: left !important;
}

.sell-my-home-page.page-container .video-section {
  max-width: 100%;
  padding-top: 60px;
  text-align: left !important;
}

.sell-my-home-page.page-container .counter-section h2 {
  font-size: 40px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
  width: 1500px;
}

.sell-my-home-page.page-container .video-section h2 {
  font-size: 40px !important;
  color: #333 !important;
  margin-bottom: 0px !important;
  font-weight: bold !important;
  
  margin: auto;
  text-align: center;
}

.sell-my-home-page.page-container .counter-row {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    gap: 10px !important;
    padding: 0px 0px;
    max-width:1500px;
  }

.sell-my-home-page.page-container .counter-box {
  flex: 1 !important;
  padding: 30px !important;
  background: white !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s ease !important;
}

.sell-my-home-page.page-container .counter-box:hover {
  transform: translateY(-5px) !important;
}

.sell-my-home-page.page-container .counter-icon {
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 15px !important;
}

.sell-my-home-page.page-container .counter-box h3 {
  font-size: 28px !important;
  color: black !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  font-family: 'Inter';
}

.sell-my-home-page.page-container .counter-box p {
  font-size: 17px !important;
  color: #666 !important;
  line-height: 1.4 !important;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 0px;
}

/* Video Section */
.sell-my-home-page.page-container .video-container {
  max-width: 1500px !important;
  margin: auto;
  position: relative !important;
  border-radius: 5px !important;
  margin-bottom:40px!important;
  margin-top: 40px!important;
  overflow: hidden !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
}

.sell-my-home-page.page-container .unique-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background: rgba(156, 220, 248, 0.9) !important;
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: transform 0.3s ease !important;
  z-index: 3 !important;
}

.sell-my-home-page.page-container .unique-play-button:hover {
  transform: translate(-50%, -50%) scale(1.1) !important;
}

.sell-my-home-page.page-container .unique-play-icon {
  font-size: 30px !important;
  color: white !important;
  margin-left: 5px !important;
}

/* Free Home Evaluation Section */
.sell-my-home-page.page-container .free-home-evaluation {
  max-width: 1500px !important;
  margin: 60px auto !important;
  padding: 40px !important;
  background: white !important;
  border-radius: 5px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
  text-align: center !important;
}



.sell-my-home-page.page-container .evaluation-text {
  font-size: 32px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
}

.sell-my-home-page.page-container .partner-text {
  color: #9CDCF8 !important;
}

.sell-my-home-page.page-container .evaluation-para {
  font-size: 18px !important;
  color: #666 !important;
  line-height: 1.6 !important;
  margin-bottom: 30px !important;
}

.sell-my-home-page.page-container .read-more-link {
  display: inline-block !important;
  background-color: #9CDCF8 !important;
  color: black !important;
  padding: 14px 28px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  font-weight: bold !important;
  transition: background-color 0.3s ease !important;
}

.sell-my-home-page.page-container .read-more-link:hover {
  background-color: #7cb8d6 !important;
}

.sell-my-home-page.page-container .steps-container {
  width: 100%;
  margin-top: 0px;
  background-color: #ECF5F9;
  padding: 60px 0;
}

.sell-my-home-page.page-container .content-image-section {
  display: flex !important;
  align-items: stretch !important;  /* Changed from center to stretch */
  gap: 40px !important;
  margin: auto;
  opacity: 1 !important;
  transform: translateY(20px) !important;
  transition: opacity 0.6s ease, transform 0.6s ease !important;
  max-width: 1500px !important;
  margin-bottom:60px;
  padding: 0 20px;
  
}

.sell-my-home-page.page-container .content-image-section.visible {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.sell-my-home-page.page-container .content-image-section.text-right {
  flex-direction: row-reverse !important;
}

.sell-my-home-page.page-container .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  flex: 1;
  
  background-color: white;
  align-self: stretch;
}

.sell-my-home-page.page-container .advertising-partners-ticker-wrapper {
  max-width:none!important;
}

.sell-my-home-page.page-container .text-content h2 {
  font-size: 32px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif;
  text-align:left!important;
  margin-top:0px;
  margin:0px!important;
}

.sell-my-home-page.page-container .calculator-section {
  margin-bottom:40px;
  border-radius:5px;
}

.sell-my-home-page.page-container .commission-calculator-container h5 {
text-align:center!important;

}

.sell-my-home-page.page-container .calculator-section h2 {
  font-size: 32px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif;
  text-align:center!important;
  margin-right:0px!important;
  margin-left:0px!important;
}

.sell-my-home-page.page-container  .text-right h2 {
  font-size: 32px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
  font-family: 'Inter', sans-serif;
  text-align:right!important;
  margin-right:0px!important;
}

.sell-my-home-page.page-container .text-content p {
  font-size: 18px !important;
  color: #666 !important;
  line-height: 1.6 !important;
  font-family: 'Inter', sans-serif;
}

.sell-my-home-page.page-container .image-content {
  flex: 1 !important;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex !important; /* Added */
  align-items: stretch !important; /* Added */
  height: 100% !important; /* Added */
}

.sell-my-home-page.page-container .image-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.15);
}

.sell-my-home-page.page-container .image-placeholder {
  width: 100% !important;
  height: 100% !important; /* Changed from auto */
  border-radius: 5px;
  transition: transform 0.3s ease;
  object-fit: cover !important; /* Added to maintain aspect ratio */
}

.sell-my-home-page.page-container .image-placeholder:hover {
  transform: scale(1.05);
}

/* Featured Listings Section */
.sell-my-home-page.page-container .featured-listings-heading {
  font-size: 36px !important;
  color: #333 !important;
  text-align: center !important;
  margin: 0px;
  font-weight: bold !important;
  padding-top: 60px;
}

/* Toronto Star Section */
.sell-my-home-page.page-container .toronto-star-wrapper {
  max-width: 1500px !important;
  margin: 60px auto !important;
  padding: 0 0px !important;
}

.sell-my-home-page.page-container .toronto-star-container {
  display: flex !important;
  gap: 40px !important;
  align-items: center !important;
  background: white !important;
  border-radius: 5px !important;
  padding: 40px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1) !important;
  margin: 0px;
}

.sell-my-home-page.page-container .home-page-toronto-star {
  flex: 1 !important;
}

.sell-my-home-page.page-container .thestar-logo {
  width: 200px !important;
  height: auto !important;
  margin-bottom: 0px !important;
}

.sell-my-home-page.page-container .toronto-star-heading {
  font-size: 28px !important;
  color: #333 !important;
  margin-bottom: 20px !important;
  font-weight: bold !important;
}

.sell-my-home-page.page-container .toronto-star-paragraph {
  font-size: 16px !important;
  color: #666 !important;
  line-height: 1.6 !important;
  margin-bottom: 30px !important;
}

.sell-my-home-page.page-container .toronto-star-image-column {
  flex: 0.4 !important;
}

.sell-my-home-page.page-container .toronto-star-image {
  width: 100% !important;
  height: auto !important;
  border-radius: 5px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .sell-my-home-page.page-container .sell-home-landing-content {
    flex-direction: column !important;
    gap: 20px !important;
    padding-bottom:0px!important;
    padding:20px!important;
  }

  .sell-my-home-page.page-container .sell-home-landing-left,
  .sell-my-home-page.page-container .sell-home-landing-right {
    flex: 1 0 100% !important;
  }

  .sell-my-home-page.page-container .sell-home-landing-right {
    display: none !important;
  }

  .sell-my-home-page.page-container .counter-section {
    margin-right:0px!important;
    margin-left:0px!important;
    
  }

  .sell-my-home-page.page-container .review-card.desktop-only {
    display: none !important;
  }

  .sell-my-home-page.page-container .advertising-partners-container {
    padding: 0px 0px;
  }

  .sell-my-home-page.page-container .review-card.mobile-only {
    display: block !important;
    
    margin: 20px 20px !important;
    margin-top:0px!important;
    padding:20px!important;
  }

  .sell-my-home-page.page-container .reviews-background {
    display: none !important;
  }

  .sell-my-home-page.page-container .counter-row {
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px 20px;
  }

  .sell-my-home-page.page-container .counter-box {
    max-width: 100% !important;
  }



  .sell-my-home-page.page-container .content-image-section,
  .sell-my-home-page.page-container .content-image-section.text-right {
    flex-direction: column !important;
    gap: 20px !important;
    padding: 0 20px;
    margin-bottom:0px!important;
  }

  .sell-my-home-page.page-container .content-image-section,
  .sell-my-home-page.page-container .content-image-section.text-right p {
    text-align:right!important;
  }

  .sell-my-home-page.page-container .toronto-star-container {
    flex-direction: column !important;
  }

  .sell-my-home-page.page-container .toronto-star-image-column {
    order: -1 !important;
  }

  .sell-my-home-page.page-container .sell-home-landing-title {
    font-size: 32px !important;
  }

  .sell-my-home-page.page-container .evaluation-text {
    font-size: 28px !important;
  }

  .sell-my-home-page.page-container .text-content h2 {
    font-size: 24px !important;
    margin-bottom:0px!important;
    padding:0px!important;
  }

  .sell-my-home-page.page-container .text-content,
  .sell-my-home-page.page-container .image-content {
    width: 100%;
    margin-bottom: 20px;
  }

  .sell-my-home-page.page-container .text-content p {
    font-size: 14px !important;
    text-align:left!important;
  }
}
/* Contact Section */
.sell-my-home-page.page-container .contact-section {
  max-width: 1500px;
  margin: 60px auto;
  padding: 0 20px;
}

.sell-my-home-page.page-container .contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  background: transparent;
  border-radius: 5px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0);
  padding: 0px;
}

.sell-my-home-page.page-container .contact-icon {
  width:55px!important;
  height:55px!important;
}

.sell-my-home-page.page-container .contact-info-box {
  background: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  vertical-align:middle!important;
}

.sell-my-home-page.page-container .contact-info-box h2 {
  font-size: 34px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  text-align:left;
}

.sell-my-home-page.page-container .contact-divider {
  width: 50px;
  height: 3px;
  background-color: #9CDCF8;
  margin-bottom: 20px;
}

.sell-my-home-page.page-container .contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #333;
  background-color:white;
  padding:35px;
  box-shadow: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-size: 18px;
  max-width:100%!important;
  width:100%!important;
  font-family: 'Inter'!important;
  transition: color 0.3s ease;
  
}

.sell-my-home-page.page-container .contact-item:hover {
  color: #7cb8d6;
}

.sell-my-home-page.page-container .contact-icon {
  margin-right: 20px;
  color: #9CDCF8;
  padding:10px;
  background-color: #ECF5F9;
  border-radius:5px;
}

.sell-my-home-page.page-container .contact-form-container {
  background: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sell-my-home-page.page-container .contact-form-container h2 {
  font-size: 34px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  text-align:left;
}

.sell-my-home-page.page-container .contact-form-container form {
  display: flex;
  flex-direction: column;
}

.sell-my-home-page.page-container .contact-form-container input,
.sell-my-home-page.page-container .contact-form-container textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  transition: border-color 0.3s ease;
}

.sell-my-home-page.page-container .contact-form-container input:focus,
.sell-my-home-page.page-container .contact-form-container textarea:focus {
  border-color: #9CDCF8;
  outline: none;
}

.sell-my-home-page.page-container .contact-form-container textarea {
  resize: vertical;
  min-height: 150px;
}

.sell-my-home-page.page-container .contact-form-container button {
  background-color: #9CDCF8;
  color: #000;
  padding: 14px 28px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sell-my-home-page.page-container .contact-form-container button:hover {
  background-color: #7cb8d6;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .sell-my-home-page.page-container .contact-grid {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .sell-my-home-page.page-container .contact-info-box,
  .sell-my-home-page.page-container .contact-form-container {
    padding: 20px;
  }

  .sell-my-home-page.page-container .contact-info-box h2,
  .sell-my-home-page.page-container .contact-form-container h2 {
    font-size: 24px;
  }

  .sell-my-home-page.page-container .contact-item {
    font-size: 14px;
  }

  .sell-my-home-page.page-container .contact-form-container input,
  .sell-my-home-page.page-container .contact-form-container textarea {
    font-size: 14px;
  }

  .sell-my-home-page.page-container .contact-form-container button {
    font-size: 14px;
    padding: 12px 24px;
  }
}

/* Enhanced Mobile Responsive Styles */
@media (max-width: 768px) {
  /* General Layout Adjustments */
  .sell-my-home-page.page-container {
    padding: 0px!important;
  }

  /* Landing Content */
  .sell-my-home-page.page-container .sell-home-landing-badge {
    font-size: 12px !important;
    padding: 6px 12px !important;
  }

  .sell-my-home-page.page-container .sell-home-landing-subtitle {
    font-size: 16px !important;
    margin-bottom: 20px !important;
  }

  .sell-my-home-page.page-container .sell-home-landing-button,
  .sell-my-home-page.page-container .sell-home-landing-button-right {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
    padding: 12px 20px !important;
  }

  /* Reviews Section */
  .sell-my-home-page.page-container .review-card {
    padding: 20px !important;
    margin: 10px !important;
  }

  .sell-my-home-page.page-container .review-author {
    font-size: 16px !important;
  }

  .sell-my-home-page.page-container .review-text {
    font-size: 14px !important;
    max-height: 120px !important;
  }

  .sell-my-home-page.page-container .nav-button {
    font-size: 20px !important;
    padding: 3px 12px !important;
  }

  /* Counter Section */
  .sell-my-home-page.page-container .counter-section h2 {
    font-size: 28px !important;
    width: 100% !important;
    padding: 0 20px !important;
    margin-bottom: 20px !important;
    text-align:center!important;
  }

  .sell-my-home-page.page-container .counter-box {
    margin:0px!important;
    margin-bottom: 15px !important;
    padding: 20px !important;
  }

  .sell-my-home-page.page-container .counter-icon {
    width: 40px !important;
    height: 40px !important;
  }

  /* Video Section */
  .sell-my-home-page.page-container .video-section h2 {
    font-size: 28px !important;
    width: 100% !important;
    padding: 20px 20px !important;
  }

  .sell-my-home-page.page-container .video-container {
    width: 100% !important;
    margin: 20px 0 !important;
    margin-bottom:40px!important;
  }

  .sell-my-home-page.page-container .unique-play-button {
    width: 60px !important;
    height: 60px !important;
  }

  /* Steps Container */
  .sell-my-home-page.page-container .steps-container {
    padding: 30px 0 !important;
  }

  .sell-my-home-page.page-container .text-content {
    padding: 20px !important;
  }

  

  /* Contact Section */
  .sell-my-home-page.page-container .contact-section {
    margin: 30px auto !important;
    padding: 0 15px !important;
  }

  .sell-my-home-page.page-container .contact-info-box,
  .sell-my-home-page.page-container .contact-form-container {
    padding: 20px !important;
  }

  .sell-my-home-page.page-container .contact-item {
    padding: 20px !important;
    font-size: 14px !important;
  }

  .sell-my-home-page.page-container .contact-icon {
    width: 40px !important;
    height: 40px !important;
    padding: 8px !important;
  }

  /* Toronto Star Section */
  .sell-my-home-page.page-container .toronto-star-wrapper {
    margin: 30px auto !important;
  }

  .sell-my-home-page.page-container .toronto-star-container {
    padding: 20px !important;
  }

  .sell-my-home-page.page-container .toronto-star-heading {
    font-size: 24px !important;
    margin-bottom:0px!important;
  }

  .sell-my-home-page.page-container .toronto-star-paragraph {
    font-size: 14px !important;
  }

  .sell-my-home-page.page-container .read-more-link {
    width: 100% !important;
    text-align: center !important;
    display: block !important;
  }

  /* Featured Listings */
  .sell-my-home-page.page-container .featured-listings-heading {
    font-size: 28px !important;
    padding-top: 30px !important;
  }
}

/* Additional Small Screen Optimizations */
@media (max-width: 480px) {
  .sell-my-home-page.page-container .sell-home-landing-title {
    font-size: 28px !important;
  }

  .sell-my-home-page.page-container .counter-box h3 {
    font-size: 24px !important;
  }

  .sell-my-home-page.page-container .counter-box p {
    font-size: 14px !important;
  }

  .sell-my-home-page.page-container .text-content h2 {
    font-size: 20px !important;
  }

  .sell-my-home-page.page-container .text-content p {
    font-size: 14px !important;
  }

  .sell-my-home-page.page-container .contact-info-box h2,
  .sell-my-home-page.page-container .contact-form-container h2 {
    font-size: 24px !important;
  }
}

/* Tablet Optimization */
@media (min-width: 769px) and (max-width: 1024px) {
  .sell-my-home-page.page-container .sell-home-landing-content {
    padding: 0 20px !important;
  }

  .sell-my-home-page.page-container .counter-row {
    gap: 20px !important;
  }

  .sell-my-home-page.page-container .content-image-section {
    padding: 0 20px !important;
  }

  .sell-my-home-page.page-container .contact-grid {
    gap: 20px !important;
  }
}

/* Landscape Mode Optimization */
@media (max-width: 768px) and (orientation: landscape) {
  .sell-my-home-page.page-container .video-container {
    max-height: 70vh !important;
  }

  .sell-my-home-page.page-container .image-content {
    height: 300px !important;
  }
}