/* Modern 1% Lists Section */
.one-percent-lists-section {
  width: 100% !important;
  max-width: 1500px !important;
  margin: 6rem auto !important;
  position: relative !important;
}

.one-percent-header {
  text-align: center !important;
  margin-bottom: 4rem !important;
}

.one-percent-header h2 {
  font-size: 2.8rem !important;
  color: #333 !important;
  margin-bottom: 1.5rem !important;
  position: relative !important;
  display: inline-block !important;
}

.one-percent-header h2::after {
  content: "" !important;
  position: absolute !important;
  bottom: -10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 80px !important;
  height: 4px !important;
  background: linear-gradient(90deg, #9CDCF8, #7cb8d6) !important;
  border-radius: 2px !important;
}

.one-percent-header p {
  font-size: 1.3rem !important;
  color: #555 !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  line-height: 1.6 !important;
}

.one-percent-grid {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 2.5rem !important;
}

.one-percent-card {
  background: white !important;
  border-radius: 16px !important;
  padding: 2.5rem !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  border-top: 4px solid #9CDCF8 !important;
}

.one-percent-card:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 15px 40px rgba(156, 220, 248, 0.2) !important;
}

.one-percent-card-icon {
  width: 60px !important;
  height: 60px !important;
  background-color: rgba(156, 220, 248, 0.2) !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 1.5rem !important;
  margin:auto!important;
}

.one-percent-card h3 {
  font-size: 1.6rem !important;
  color: #333 !important;
  margin-bottom: 1rem !important;
}

.one-percent-card p {
  color: #555 !important;
  font-size: 1.1rem !important;
  line-height: 1.6 !important;
  margin-bottom: 1.5rem !important;
}

.one-percent-benefits {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.one-percent-benefit-item {
  display: flex !important;
  align-items: flex-start !important;
  margin-bottom: 1rem !important;
  padding: 0.8rem !important;
  border-radius: 8px !important;
  transition: background-color 0.3s ease !important;
}

.one-percent-benefit-item:hover {
  background-color: rgba(156, 220, 248, 0.1) !important;
}

.one-percent-benefit-bullet {
  width: 24px !important;
  height: 24px !important;
  background-color: #9CDCF8 !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1rem !important;
  flex-shrink: 0 !important;
  color: white !important;
  font-weight: bold !important;
}

.one-percent-benefit-text {
  color: #555 !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important;
}

.one-percent-benefit-text strong {
  color: #333 !important;
  font-weight: 600 !important;
}

.one-percent-cta {
  grid-column: 1 / -1 !important;
  text-align: center !important;
  padding: 3rem !important;
  background: linear-gradient(135deg, rgba(156, 220, 248, 0.1), rgba(124, 184, 214, 0.1)) !important;
  border-radius: 16px !important;
  margin-top: 1rem !important;
}

.one-percent-cta p {
  font-size: 1.4rem !important;
  color: #333 !important;
  margin-bottom: 2rem !important;
  font-style: italic !important;
  font-weight: 600 !important;
}

.one-percent-join-btn {
  background-color: #9CDCF8;
  color: #000 !important;
  padding: 16px 40px !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  
  display: inline-flex !important;
  align-items: center !important;
}

.one-percent-join-btn:hover {
  transform: translateY(-3px) !important;
  box-shadow: 0 15px 35px rgba(156, 220, 248, 0.5) !important;
}

.one-percent-btn-icon {
  margin-left: 12px !important;
  transition: transform 0.3s ease !important;
}

.one-percent-join-btn:hover .one-percent-btn-icon {
  transform: translateX(8px) !important;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .one-percent-header h2 {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 992px) {
  .one-percent-grid {
    grid-template-columns: 1fr !important;
  }
  
  .one-percent-card {
    padding: 2rem !important;
  }
}

@media (max-width: 768px) {
  .one-percent-header h2 {
    font-size: 2.2rem !important;
  }
  
  .one-percent-header p {
    font-size: 1.1rem !important;
  }
  
  .one-percent-cta {
    padding: 2rem !important;
  }
  
  .one-percent-cta p {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 480px) {
  .one-percent-header h2 {
    font-size: 2rem !important;
  }
  
  .one-percent-card {
    padding: 1.5rem !important;
  }
  
  .one-percent-card-icon {
    width: 50px !important;
    height: 50px !important;
  }
}