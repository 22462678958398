/* Footer.css */
.footer {
    width: 100%;
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;

    margin-left:auto;
    margin-right:auto;
    border:solid;border-top:1px solid #ddd;border-left: none;border-bottom:none;border-right:none;
  }

  .footer-top-container {background-color:#fff;width:100%;padding-left:15px;padding-right:15px;
    }
  
  .footer-container {
    max-width:1500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0; /* Add padding inside the container */
  }

  .canadian-ownership {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
  }
  
  .canadian-ownership img.canada-flag {
    height: 25px;
    width: auto;
    object-fit: contain;
  }
  
  .canadian-ownership p {
    margin: 0;
    font-weight: 500;
    color: black;
    font-size: 0.9rem;
    font-family:'Inter';
  }
  
  /* Mobile styles */
  @media screen and (max-width: 768px) {
    .canadian-ownership {
      flex-direction: column;
      gap: 0.5rem;
      padding-top:20px;
    }
  
    .canadian-ownership img.canada-flag {
      height: 30px; /* Slightly larger on mobile for better visibility */
    }
  
    .canadian-ownership p {
      text-align: center;
    }
  }

  

   h5 {
    text-align: left;
margin-top: 0px;
margin-bottom:0px;
    padding:15px;
    font-size: 1.3rem;
    color: #000;
    font-family: 'Inter';
    max-width: 1500px;
    background-color:white;
    
}

@media (max-width:1024px) {
  h5 {
    text-align: left ;
    margin-top: 15px ;
    margin-bottom: 15px ;
    padding-left:0px;
    padding-right:0px;
    font-size: 1.3rem ;
    color: #000;
    font-family: 'Inter' ;
    max-width: 1500px;;
    
}
}


@media (max-width:768px) {
  h5 {
    text-align: left !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    padding:0px;
    font-size: 1.3rem !important;
    color: #000;
    font-family: 'Inter' !important;
    max-width: 1500px;;
    
}
}





.advertising-container {
  justify-content:center;
  align-content: center;
  align-items:center;
  display:grid;
  background-color:white;
  
}



@media (max-width: 1024px) {
  .advertising-container {
      padding: 15px;
      padding-top:15px;
      padding-bottom:15px;
      background-color: #fff;
      font-family: 'Inter';
      font-size: 2rem;
      text-align: center;
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
  }}



  @media (max-width: 1024px) { .footer-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display:block;
    margin: 0;
  }}
  
  .footer-links {
    list-style: none;
    display: flex;
    gap: 2.5rem; /* Space the links out more */
    font-family: Inter
    ;
  }

  @media (max-width: 1024px) { .footer-links {display:none;}}
  
  .footer-links li {
    display: flex;
    align-items: center;
  }
  
  .footer-links .icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
  
  .contact-link {
    text-decoration: none;
    color: #000;
    font-size: 20px; /* Adjust the font size */
    transition: color 0.3s ease;
  }
  
  .contact-link:hover {
    color: #9CDCF8; /* Change this to your desired hover color */
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-links {
      flex-direction: column;
      gap: 1rem; /* Adjust the gap for mobile view */
    }
  }
  
  