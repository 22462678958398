.floating-phone-container {
  position: fixed;
  bottom: 16px;
  right: 20px;
  z-index: 50;
}

.floating-phone-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #9CDCF8;
  text-decoration: none;
}

.phone-icon {
  width: 40px;
  height: 40px;
  color: black;
}

/* Hide on desktop */
@media screen and (min-width: 768px) {
  .floating-phone-container {
    display: none;
  }
}